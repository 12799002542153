import React from "react";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <>
      <Helmet>
        <title>About HD Software Technology</title>
        <meta
          name="Description"
          content="We Design All Type of website with affordable Price"
        />
        <meta name="Keyword" content="About HD Software Technology" />
      </Helmet>
      <section className="Maxwidth">
        <img
          src={require("./img/mainBanner.jpg")}
          alt="website Design Service"
        />
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="Maxwidth">
                <img
                  src={require("./img/mainBanner.jpg")}
                  alt="website Design Service"
                />
              </div>
            </div>
            <div className="col-md-8">
              <h1>How we Help you</h1>
              <p>
                At HD Software Technology, We are a Software Development company
                that specializes in web Development, Web Design, Graphic Design,
                Digital Marketing, Data Entry and RPA Development With a
                commitment to excellence and a passion for delivering
                exceptional results.
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div>Piller</div>
          <h2>Our Core Values</h2>
          <div className="row">
            <div className="col-md-3">
              <h4>Customer Focus</h4>
              <p>
                We put our customers at the center of everything we do. We
                strive to understand their needs and deliver solutions that
                exceed their expectations.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Innovation</h4>
              <p>
                We embrace innovation as a key driver of progress and growth. We
                encourage our team to think creatively and explore new ideas,
                approaches, and technologies.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Quality</h4>
              <p>
                We are dedicated to delivering high-quality software solutions.
                We believe that quality is non-negotiable and that we must
                always strive to exceed expectations.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Collaboration</h4>
              <p>
                We believe that collaboration is essential to achieving our
                goals and making a positive impact on the world. We foster an
                environment of trust, respect, and teamwork, where everyone's
                contributions are valued and recognized.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Agility</h4>
              <p>
                We believe that agility is essential to success in the
                fast-paced world of software development. We believe that being
                agile allows us to deliver solutions that meet the needs of our
                clients in a rapidly changing market.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Continuous Learning</h4>
              <p>
                We believe that innovation and continuous learning are essential
                to staying ahead of the curve in the ever-evolving field of
                software development.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Encourage</h4>
              <p>
                We encourage our team to stay up-to-date with the latest
                technologies, tools, and techniques.
              </p>
            </div>
            <div className="col-md-3">
              <h4>Opportunities</h4>
              <p>
                We provide opportunities for our team to attend training
                programs, conferences, and workshops to enhance their skills and
                knowledge.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>HD Software Technology</title>
        <meta
          name="Description"
          content="We Design All Type of website with affordable Price"
        />
        <meta
          name="Keyword"
          content="Affordable Website Design, Creative Website Design"
        />
      </Helmet>
      <section className="Maxwidth">
        <img
          src={require("./img/mainBanner.jpg")}
          alt="website Design Service"
        />
      </section>

      <section>
        <div className="container">
          <h1>Welcome to HD Software Technology Services</h1>
          <div className="row">
            <div className="col-md-3">
              <div className="">
                <div>
                  <i class="bi bi-pencil-square"></i>
                </div>
                <h4>UIUX Design Services</h4>
                <p>
                  Crafting stunning, responsive websites tailored to your
                  brand's needs. Our designs are not just visually appealing but
                  are also optimized for performance and user experience.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div>
                  <i class="bi bi-pencil-square"></i>
                </div>
                <h4>Application Development Services</h4>
                <p>
                  Crafting stunning, responsive websites tailored to your
                  brand's needs. Our designs are not just visually appealing but
                  are also optimized for performance and user experience.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div>
                  <i class="bi bi-pencil-square"></i>
                </div>
                <h4>Digital Marketing Services</h4>
                <p>
                  Crafting stunning, responsive websites tailored to your
                  brand's needs. Our designs are not just visually appealing but
                  are also optimized for performance and user experience.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="">
                <div>
                  <i class="bi bi-pencil-square"></i>
                </div>
                <h4>Content Marketing Services</h4>
                <p>
                  Crafting stunning, responsive websites tailored to your
                  brand's needs. Our designs are not just visually appealing but
                  are also optimized for performance and user experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-start">The History of </div>
              <h2 className="text-start">Our Way to Successfull Future</h2>
              <p className="text-start">
                At [Company Name], we are a team of creative designers, savvy
                digital marketers, and innovative software developers dedicated
                to elevating your brand's online presence. With years of
                expertise in crafting bespoke digital solutions, we transform
                ideas into impactful realities.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="ourStats">
            <div className="row">
              <div className="col-md-4">
                <h4>13+</h4>
                <div>Years in business</div>
              </div>
              <div className="col-md-4">
                <h4>250 +</h4>
                <div>Projects Delivered</div>
              </div>
              <div className="col-md-4">
                <h4>50+</h4>
                <div>Trust Clients</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div>Key of HD Software Technology</div>
          <h3>Why you Join with HD Software Technology</h3>
          <div className="row">
            <div className="col-md-4">
              <h3>Customer Support</h3>
              <p>
                Developing robust, scalable, and efficient software solutions
                tailored to streamline your business processes and enhance
                productivity.
              </p>
            </div>
            <div className="col-md-4">
              <h3>Customer Support</h3>
              <p>
                Developing robust, scalable, and efficient software solutions
                tailored to streamline your business processes and enhance
                productivity.
              </p>
            </div>

            <div className="col-md-4">
              <h3>Customer Support</h3>
              <p>
                Developing robust, scalable, and efficient software solutions
                tailored to streamline your business processes and enhance
                productivity.
              </p>
            </div>
            <div className="col-md-4">
              <h3>Customer Support</h3>
              <p>
                Developing robust, scalable, and efficient software solutions
                tailored to streamline your business processes and enhance
                productivity.
              </p>
            </div>
            <div className="col-md-4">
              <h3>Customer Support</h3>
              <p>
                Developing robust, scalable, and efficient software solutions
                tailored to streamline your business processes and enhance
                productivity.
              </p>
            </div>
            <div className="col-md-4">
              <h3>Customer Support</h3>
              <p>
                Developing robust, scalable, and efficient software solutions
                tailored to streamline your business processes and enhance
                productivity.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div>Team Efforts</div>
          <h3>Our Recent Work</h3>

          <div className="row">
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
              <h5>Services Name</h5>
              <div>Read More</div>
            </div>
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
              <h5>Services Name</h5>
              <div>Read More</div>
            </div>
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
              <h5>Services Name</h5>
              <div>Read More</div>
            </div>
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
              <h5>Services Name</h5>
              <div>Read More</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div></div>
        </div>
      </section>
    </>
  );
}

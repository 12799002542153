import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us - HD Software</title>
        <meta
          name="Description"
          content="We Design All Type of website with affordable Price"
        />
        <meta
          name="Keyword"
          content="Affordable Website Design, Creative Website Design"
        />
      </Helmet>
      <section className="Maxwidth">
        <img
          src={require("./img/mainBanner.jpg")}
          alt="website Design Service"
        />
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-4 offset-2">
              <form className="text-start">
                <div>
                  <input type="text" placeholder="Enter Name" />
                </div>
                <div>
                  <input type="text" placeholder="Enter Phone" />
                </div>
                <div>
                  <input type="text" placeholder="Enter Email " />
                </div>
                <div>
                  <select>
                    <option>-- Select Purpose --</option>
                    <option>Website Design</option>
                    <option> Web Development</option>
                    <option>Digital Marketing</option>
                    <option>Other</option>
                  </select>
                </div>
                <div>
                  <button>Submit</button>
                </div>
              </form>
            </div>
            <div className="col-md-4 offset-1 text-start">
              <h2>Connect with us</h2>
              <p>Email : Contact@harshdeeptech.com</p>
              <p>Phone : XXX-XXXX-616</p>
              <p>
                Address : D-66A, Jai Vihar Colony, Najafgarh New Delhi -110043
              </p>
              <h3>Social Media</h3>
              <Link>
                <i class="bi bi-facebook"></i>
              </Link>
              <Link>
                <i class="bi bi-whatsapp"></i>
              </Link>
              <Link>
                <i class="bi bi-linkedin"></i>
              </Link>
              <Link>
                <i class="bi bi-twitter-x"></i>
              </Link>
              <Link>
                <i class="bi bi-youtube"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

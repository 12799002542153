import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <>
      <Helmet>
        <title>Services HD Software</title>
        <meta
          name="Description"
          content="We Design All Type of website with affordable Price"
        />
        <meta
          name="Keyword"
          content="Affordable Website Design, Creative Website Design"
        />
      </Helmet>
      <section className="Maxwidth">
        <img
          src={require("./img/mainBanner.jpg")}
          alt="website Design Service"
        />
      </section>
      <section>
        <div className="container">
          <h1> HD Software Technology Services</h1>
          <p>
            At HD Software Technology, we provide a range of services to help
            our clients achieve their business goals. Whether you are a small
            business looking to establish a strong online presence, or a large
            corporation in need of enterprise-level solutions, we have the
            expertise and resources to meet your unique needs and requirements.
          </p>
          <div className="row">
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <h4>Graphic Design</h4>
                <p>
                  We provide graphic design solutions to help you create
                  stunning designs that represent your brand and communicate
                  your message effectively.
                </p>
                <div>
                  <Link>Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="ftbox">
                <h4>Website Design Industries</h4>
                <hr className="divider" align="left" />
                <ul>
                  <li>
                    <Link>Logistics Website Design</Link>
                  </li>
                  <li>
                    <Link>Healthcare Website Design</Link>
                  </li>
                  <li>
                    <Link>Services Website Design</Link>
                  </li>
                  <li>
                    <Link>Food and Beverage Website Design</Link>
                  </li>
                  <li>
                    <Link>Educational Website Design </Link>
                  </li>
                  <li>
                    <Link>Finance and Banking Website Design </Link>
                  </li>
                  <li>
                    <Link>Real Estate Website Design </Link>
                  </li>
                  <li>
                    <Link>Hospitality and Travel Website Design </Link>
                  </li>
                  <li>
                    <Link>Technology and IT Website Design </Link>
                  </li>
                  <li>
                    <Link>Non-Profit and Charities Website Design </Link>
                  </li>
                  <li>
                    <Link>Government Website Design </Link>
                  </li>
                  <li>
                    <Link>Entertainment and Media Website Design </Link>
                  </li>
                  <li>
                    <Link>Automotive Website Design </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="ftbox">
                <h4>Design Services</h4>
                <hr className="divider" align="left" />
                <ul>
                  <li>
                    <Link>Website Design</Link>
                  </li>
                  <li>
                    <Link>Graphic Design</Link>
                  </li>
                  <li>
                    <Link>Logo Design</Link>
                  </li>
                  <li>
                    <Link>Brochure Design</Link>
                  </li>
                  <li>
                    <Link>PPT Design</Link>
                  </li>
                  <li>
                    <Link>Responsive Website Design</Link>
                  </li>
                  <li>
                    <Link>Custom Website Design</Link>
                  </li>
                  <li>
                    <Link>Informative Website Design</Link>
                  </li>
                  <li>
                    <Link>Portfolio Website Design</Link>
                  </li>
                  <li>
                    <Link>Static Website Design</Link>
                  </li>
                  <li>
                    <Link>Wordpress Website Design</Link>
                  </li>
                  <li>
                    <Link>Corporate Website Design</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="ftbox">
                <h4>Web Development Services</h4>
                <hr className="divider" align="left" />
                <ul>
                  <li>
                    <Link>Custom Website Development</Link>
                  </li>
                  <li>
                    <Link>E-Commerce Development</Link>
                  </li>
                  <li>
                    <Link>Content Management System (CMS) Development</Link>
                  </li>
                  <li>
                    <Link>Front-End Development </Link>
                  </li>
                  <li>
                    <Link>Back-End Development</Link>
                  </li>
                  <li>
                    <Link>PHP Website Development</Link>
                  </li>
                  <li>
                    <Link>PSD To HTML Development</Link>
                  </li>
                  <li>
                    <Link>Wordpress Website Development</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="ftbox">
                <h4>Online Marketing Services</h4>
                <hr className="divider" align="left" />
                <ul>
                  <li>
                    <Link>Search Engine Optimization (SEO)</Link>
                  </li>
                  <li>
                    <Link>Content Marketing</Link>
                  </li>
                  <li>
                    <Link>Social Media Marketing</Link>
                  </li>
                  <li>
                    <Link>Email Marketing</Link>
                  </li>
                  <li>
                    <Link>Pay-Per-Click (PPC) Advertising </Link>
                  </li>
                  <li>
                    <Link>Affiliate Marketing </Link>
                  </li>
                  <li>
                    <Link>Influencer Marketing </Link>
                  </li>
                  <li>
                    <Link>Online Public Relations </Link>
                  </li>
                  <li>
                    <Link>Mobile Marketing </Link>
                  </li>
                  <li>
                    <Link>Video Marketing </Link>
                  </li>
                  <li>
                    <Link>Web Analytics </Link>
                  </li>
                  <li>
                    <Link>Conversion Rate Optimization (CRO) </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <hr />
          </div>
          <div className="row">
            <div className="col-md-6 text-start socialmedia">
              <span>
                <i className="bi bi-facebook"></i>
              </span>
              <span>
                <i className="bi bi-linkedin"></i>
              </span>
              <span>
                <i className="bi bi-pinterest"></i>
              </span>
              <span>
                <i className="bi bi-youtube"></i>
              </span>
              <span>
                <i className="bi bi-twitter-x"></i>
              </span>
            </div>
            <div className="col-md-6 text-end">
              &copy; 2019-2023 HD Software Technology. All rights reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

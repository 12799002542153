import React from "react";
import { Helmet } from "react-helmet";
import "./Blogs.css";
import { Link } from "react-router-dom";

export default function Blogs() {
  return (
    <>
      <Helmet>
        <title>Blogs - HD Software</title>
        <meta
          name="Description"
          content="We Design All Type of website with affordable Price"
        />
        <meta
          name="Keyword"
          content="Affordable Website Design, Creative Website Design"
        />
      </Helmet>
      <section className="Maxwidth">
        <img
          src={require("./img/mainBanner.jpg")}
          alt="website Design Service"
        />
      </section>

      <section>
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="blogList">
                <h4 className="text-start">What is API ?</h4>
                <div className="text-start">11 Oct 2024</div>
                <p className="text-start">
                  A web API, or Application Programming Interface, is a set of
                  protocols and tools that allow developers to build software
                  applications that communicate with each other over the
                  internet. In other words, a web API provides a way for
                  different software systems to talk to each other and exchange
                  data.
                </p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="blogList">
                <h4 className="text-start">What is API ?</h4>
                <div className="text-start">11 Oct 2024</div>
                <p className="text-start">
                  A web API, or Application Programming Interface, is a set of
                  protocols and tools that allow developers to build software
                  applications that communicate with each other over the
                  internet. In other words, a web API provides a way for
                  different software systems to talk to each other and exchange
                  data.
                </p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="blogList">
                <h4 className="text-start">What is API ?</h4>
                <div className="text-start">11 Oct 2024</div>
                <p className="text-start">
                  A web API, or Application Programming Interface, is a set of
                  protocols and tools that allow developers to build software
                  applications that communicate with each other over the
                  internet. In other words, a web API provides a way for
                  different software systems to talk to each other and exchange
                  data.
                </p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="blogList">
                <h4 className="text-start">What is API ?</h4>
                <div className="text-start">11 Oct 2024</div>
                <p className="text-start">
                  A web API, or Application Programming Interface, is a set of
                  protocols and tools that allow developers to build software
                  applications that communicate with each other over the
                  internet. In other words, a web API provides a way for
                  different software systems to talk to each other and exchange
                  data.
                </p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="Maxwidth">
                <img src={require("./img/mainBanner.jpg")} alt="" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="blogList">
                <h4 className="text-start">What is API ?</h4>
                <div className="text-start">11 Oct 2024</div>
                <p className="text-start">
                  A web API, or Application Programming Interface, is a set of
                  protocols and tools that allow developers to build software
                  applications that communicate with each other over the
                  internet. In other words, a web API provides a way for
                  different software systems to talk to each other and exchange
                  data.
                </p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
